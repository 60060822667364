import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de fins a 12 cm de diàmetre, llis, que passa de convex a aplanat, de color marró grisenc i amb el marge ondulat. Les làmines són d’adnades a decurrents, primer blanquinoses i gris groguent amb el temps. El peu pot ser llarg o curt, però sempre ondulat i blanquinós. Les espores són blanques en massa, globoses, de 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      